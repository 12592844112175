import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAdminStatus } from '../thunks/general.thunks';

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  admin: boolean;
  adminLoading: boolean;
  currentPage: PageType;
}

export enum PageType {
  HOME = 'HOME',
  BLOGS = 'BLOGS',
  CONTACT = 'CONTACT',
  NEWS = 'NEWS',
  TEAM = 'TEAM',
  REGISTER = 'REGISTER',
  COMMUNITIES = 'COMMUNITIES',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_DASHBOARD = 'ENTERPRISE_DASHBOARD',
  ENTERPRISE_USERS = 'ENTERPRISE_USERS',
  ENTERPRISE_REPORTS = 'ENTERPRISE_REPORTS',
  ENTERPRISE_ACTIVITY_VIEW = 'ENTERPRISE_ACTIVITY_VIEW',
  ENTERPRISE_ACTIVITIES = 'ENTERPRISE_ACTIVITIES',
  ENTERPRISE_NEW_ACTIVITY = 'ENTERPRISE_NEW_ACTIVITY',
  ENTERPRISE_NEW_MEMBERS = 'ENTERPRISE_NEW_MEMBERS',
  ENTERPRISE_SETTINGS = 'ENTERPRISE_SETTINGS',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  ADMIN_COMMUNITIES = 'ADMIN_COMMUNITIES',
  ADMIN_USERS = 'ADMIN_USERS',
  ADMIN_REPORTS = 'ADMIN_REPORTS',
  ADMIN_ACTIVITY_VIEW = 'ADMIN_ACTIVITY_VIEW',
  ADMIN_ACTIVITIES = 'ADMIN_ACTIVITIES',
  ADMIN_NEW_ACTIVITY = 'ADMIN_NEW_ACTIVITY',
  ADMIN_NEW_MEMBERS = 'ADMIN_NEW_MEMBERS',
  ADMIN_NEW_ADMINS = 'ADMIN_NEW_ADMINS',
}

const generalInitialState: IProps = {
  admin: false,
  adminLoading: false,
  currentPage: PageType.HOME,
};

const generalSlice = createSlice({
  name: 'general_data',
  initialState: generalInitialState,
  reducers: {
    // Setters
    setAdmin: (state: IProps, { payload }: PayloadAction<boolean>) => {
      state.admin = payload;
    },
    setCurrentPage: (state: IProps, { payload }: PayloadAction<PageType>) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getAdminStatus.fulfilled,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.admin = payload;
        state.adminLoading = false;
      }
    );
    builder.addCase(
      getAdminStatus.pending,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.adminLoading = true;
      }
    );
  },
});

export const { setAdmin, setCurrentPage } = generalSlice.actions;

export default generalSlice.reducer;
