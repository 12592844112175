import { Facebook, Instagram } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export function Footer() {
  const navigate = useNavigate();
  function scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        backgroundColor: '#404040',
        padding: 50,
        color: 'white',
        textAlign: 'left',
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={4}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Typography
          variant={'h6'}
          fontWeight={'bold'}
          style={{ fontFamily: 'Urbanist' }}
        >
          Company Information:
        </Typography>
        <Typography variant={'body1'} align="left">
          RouteBuddies Limited,
          <br /> 28 Schooner Way,
          <br /> CF10 4EQ, Cardiff
          <br /> Company #13949733
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={4}
        alignItems={'center'}
        flexDirection={'column'}
        style={{ color: 'white !important' }}
      >
        <Typography
          variant={'h6'}
          style={{ fontFamily: 'Urbanist' }}
          fontWeight={'bold'}
        >
          Useful Links:
        </Typography>
        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/privacypolicy');
            scrollTop();
          }}
        >
          <Typography variant={'body1'}>Privacy Policy</Typography>
        </Button>
        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/termsandconditions');
            scrollTop();
          }}
        >
          <Typography variant={'body1'}>Terms and Conditions</Typography>
        </Button>

        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/contact');
            scrollTop();
          }}
        >
          <Typography variant={'body1'}>Contact Us</Typography>
        </Button>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={4}
        alignItems={'center'}
        justifyContent={'center'}
        style={{ color: 'white !important' }}
      >
        <Typography
          variant={'h6'}
          style={{ fontFamily: 'Urbanist' }}
          fontWeight={'bold'}
        >
          Follow Us
        </Typography>

        <Grid container xs={12} justifyContent={'space-evenly'}>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.facebook.com/routebuddiesapp';
              }}
            >
              <Facebook color={'primary'} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.instagram.com/@routebuddiesapp';
              }}
            >
              <Instagram color={'primary'} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.tiktok.com/@routebuddiesapp';
              }}
            >
              <Typography
                style={{ fontFamily: 'Urbanist', fontSize: 13 }}
                variant="body1"
                color={'primary'}
              >
                TikTok
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
