import { CircularProgress, Container, Modal, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../services/login_services';
import {
  getAllNeededVerifications,
  getOlderVerifications,
} from '../../services/report_services';
import { VerificationModel } from '../../models/VerificationModel';
import VerificationCard from '../components/VerificationCard';
import VerificationInfo from '../components/VerificationInfo';
import { Helmet } from 'react-helmet';

export default function Verifications({ theme, mapboxgl }: any) {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);

  //States for data
  const [allNeededVerifications, setAllNeededVerifications] = useState<
    VerificationModel[]
  >([]);
  const [allNeededVerificationLoading, setAllNeededVerificationsLoading] =
    useState<any>(false);

  const [olderVerifications, setOlderVerifications] = useState<
    VerificationModel[]
  >([]);
  const [olderVerificationLoading, setOlderVerificationsLoading] =
    useState<any>(false);

  const [selectedVerification, setSelectedVerification] = useState<any>(null);

  const [numSignups, setNumSignups] = useState(0);
  //Check if authenticated
  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getAllNeededVerifications(
        setAllNeededVerifications,
        setAllNeededVerificationsLoading
      );
      getOlderVerifications(
        setOlderVerifications,
        setOlderVerificationsLoading
      );
    }

    return function cleanup() {
      setAllNeededVerifications([]);
      setSelectedVerification(null);
    };
  }, [loggedIn]);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar
        theme={theme}
        navigate={navigate}
        currentPage={'verification'}
        loggedIn={loggedIn}
      />

      <Container
        style={{
          backgroundColor: theme.palette.secondary.main,
          minHeight: 'calc(100vh - 48px)',
          width: '100vw',
          marginTop: '48px',
          padding: 15,
        }}
      >
        <Grid
          container
          sx={{ marginTop: 10, color: 'grey' }}
          flexDirection={'column'}
        >
          <Grid container item flexDirection={'column'} xs={12}>
            <Typography
              color={'inherit'}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Recent Verifications
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {allNeededVerificationLoading ? (
                <CircularProgress />
              ) : allNeededVerifications === null ||
                allNeededVerifications.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No recent reports to show'}
                  />
                  <Typography>No recent reports to show.</Typography>
                </Grid>
              ) : (
                allNeededVerifications.map((ver: any) => {
                  return (
                    <Grid item xs={4}>
                      {ver !== null && (
                        <VerificationCard
                          theme={theme}
                          ver={ver.ver}
                          user={ver.user}
                          setSelectedVerification={setSelectedVerification}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>

          {/* <Grid container item flexDirection={'column'} xs={12}>
            <Typography
              color={'inherit'}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Older Verifications
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {olderVerificationLoading ? (
                <CircularProgress />
              ) : olderVerifications === null ||
                olderVerifications.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No recent reports to show'}
                  />
                  <Typography>No recent reports to show.</Typography>
                </Grid>
              ) : (
                olderVerifications.map((ver: any) => {
                  return (
                    <Grid item xs={4}>
                      {ver !== null && (
                        <VerificationCard
                          theme={theme}
                          ver={ver.ver}
                          user={ver.user}
                          setSelectedVerification={setSelectedVerification}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid> */}
        </Grid>
        <Modal
          open={selectedVerification !== null}
          onClose={() => {
            setSelectedVerification(null);
          }}
          aria-labelledby="View Verification"
          aria-describedby="Learn more about this report."
        >
          <Grid
            container
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
            >
              <VerificationInfo
                theme={theme}
                selectedVerification={selectedVerification}
                setSelectedVerification={setSelectedVerification}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </>
  );
}
