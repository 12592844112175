import { Button, Grid, Link, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { logoutUser } from '../../services/login_services';
import { getAllNeededVerifications } from '../../services/report_services';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import IconMenu from './IconMenu';
import { RoundedButton } from './RoundedButton';
import { Business } from '@mui/icons-material';

export default function NavBar({
  navigate,
  loggedIn,
  transparent = false,
}: any) {
  const theme = useTheme();
  const [allNeededVerifications, setAllNeededVerifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const [allNeededVerificationsLoading, setAllNeededVerificationsLoading] =
    useState(false);
  const { admin, currentPage } = useSelector(
    (state: any) => state.GeneralSlice
  );

  useEffect(() => {
    if (admin) {
      getAllNeededVerifications(
        setAllNeededVerifications,
        setAllNeededVerificationsLoading
      );
    }
  }, [admin, currentPage]);
  return (
    <>
      <ToastContainer />
      <AppBar
        position="static"
        style={{
          backgroundColor: !admin && !transparent ? '#cd0438' : 'transparent',
          color: '#fff',
          zIndex: 3,
          position: 'absolute',
          top: 0,
          boxShadow: 'none',
          height: '80px',
        }}
      >
        <Grid
          sx={{ height: '100%' }}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid
            xs={11}
            sm={11}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            alignContent={'center'}
          >
            {/* Image and logo */}
            <Grid
              container
              xs={5}
              sm={7}
              item
              justifyContent={'space-between'}
              alignContent={'flex-end'}
            >
              <Link href={'/'}>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems={'center'}
                  alignContent={'center'}
                  style={{ color: 'white' }}
                >
                  <img
                    src="/static/white_logo_icon_only.png"
                    style={{ height: '40px' }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{ paddingLeft: 15, fontFamily: 'UrbanistBold' }}
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                  >
                    RouteBuddies
                  </Typography>
                </Grid>
              </Link>
            </Grid>

            <Grid container item sm={5} xs={5} justifyContent={'flex-end'}>
              {loggedIn ? (
                <>
                  <Grid item>
                    <Button
                      variant={'text'}
                      color={'secondary'}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/reports');
                      }}
                      style={
                        currentPage === 'report'
                          ? { borderRadius: 0, borderBottom: '2px solid white' }
                          : { paddingBottom: '2px' }
                      }
                    >
                      Reports
                    </Button>
                    <Button
                      variant={'text'}
                      color={'secondary'}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/users');
                      }}
                      style={
                        currentPage === 'users'
                          ? { borderRadius: 0, borderBottom: '2px solid white' }
                          : { paddingBottom: '2px' }
                      }
                    >
                      Members
                    </Button>
                    <Button
                      variant={'text'}
                      color={'secondary'}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/verifications');
                      }}
                      style={
                        currentPage === 'verification'
                          ? { borderRadius: 0, borderBottom: '2px solid white' }
                          : { paddingBottom: '2px' }
                      }
                    >
                      Verifications ({allNeededVerifications.length})
                    </Button>
                  </Grid>
                  <Button
                    onClick={() => {
                      logoutUser(navigate);
                    }}
                    variant={'outlined'}
                    color={'secondary'}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Grid item>
                    <RoundedButton
                      defaultColor={'#fff'}
                      defaultBackgroundColor={'#cd0438'}
                      hoverColor={'#cd0438'}
                      hoverBackgroundColor={'#fff'}
                      onClick={() => {
                        window !== null &&
                          window.open !== null &&
                          window
                            .open(
                              'https://routebuddies.com/?appTrigger=true',
                              '_blank'
                            )!
                            .focus();
                      }}
                      width={180}
                      style={{ marginTop: 0 }}
                    >
                      Download
                    </RoundedButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
