import { Campaign, NotificationAddRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router';

export function VSNIAnnouncement() {
  const navigate = useNavigate();
  return (
    <Marquee style={{ backgroundColor: '#fbff00' }}>
      <Grid
        item
        container
        xs={12}
        sx={{ padding: 2, cursor: 'pointer' }}
        justifyContent={'center'}
        onClick={() => {
          navigate('/VSNI');
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>
        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>

        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>

        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>
        <Campaign style={{ marginRight: '30px' }} />
      </Grid>
    </Marquee>
  );
}
