import { Slide, Fade, Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

import NavBar from '../components/NavBar';
import { useLocation, useNavigate, useParams } from 'react-router';
import '../../index.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  executeScroll,
  getCurrentDateTime,
} from '../../services/home_services';
import useAnalyticsEventTracker from '../../services/analytics_services';
import { primaryColor } from '../../assets/styles/styles';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import { RoundedButton } from '../components/RoundedButton';
import { getMobileOperatingSystem } from '../../services/general_services';
import Features from '../components/Features';
import { VSNIAnnouncement } from '../components/VSNIAnnouncement';
import { db } from '../../services/firebase_services';

export default function Home() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const appTrigger = params.get('appTrigger');
  const link = params.get('link');
  const id = params.get('id');

  const sponsor = params.get('sponsorInfo');

  const localStyles = {
    centerVert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '200px',
    },
  };
  const navigate = useNavigate();
  const theme = useTheme();

  const [initialAnimationStart, setInitialAnimation] = useState(false);
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [fadeAnimation2, setFadeAnimation2] = useState(false);

  const { install } = useParams();
  const deepLink = 'routebuddies://';

  useEffect(() => {
    let fallbackTimeout: any;

    const redirectToDeepLink = () => {
      let location = deepLink;

      if (id && link) {
        // Make deeplink properly... E.g. routebuddies://join/communityId
        location += `${link}/${id}`;
      } else if (link) {
        location += link;
      }

      console.log('Deepink?');
      window.location.href = location;
    };

    async function redirectToSponsor() {
      let dateTime = getCurrentDateTime();
      await db.ref('/new_data/sponsor_visits/impactive_it/').push({
        dateTime,
      });
      let location = 'https://impactiveit.com';

      window.location.href = location;
    }

    const redirectToFallback = () => {
      const platform = getMobileOperatingSystem();
      const fallbackLink =
        platform === 'Android'
          ? 'https://play.google.com/store/apps/details?id=com.routebuddies'
          : 'https://apps.apple.com/gb/app/routebuddies/id6443986928';

      console.log('Fallback?');
      window.location.href = fallbackLink;
    };

    const tryRedirect = () => {
      redirectToDeepLink();

      // If the navigation fails, redirect to the fallback link
      fallbackTimeout = setTimeout(() => {
        redirectToFallback();
      }, 1000); // Adjust timeout as needed
    };

    const handleBlur = () => {
      // If the window loses focus before the timeout fires, clear the timeout
      clearTimeout(fallbackTimeout);
    };

    if (appTrigger) {
      gaEventTracker('appTrigger');

      tryRedirect();

      window.addEventListener('blur', handleBlur);

      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('blur', handleBlur);
      };
    } else if (sponsor) {
      redirectToSponsor();
    }
  }, [appTrigger]);

  useEffect(() => {
    setInitialAnimation(true);
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);
  const [appOpen, setAppOpen] = useState(false);
  // Pre-reg fields

  const gaEventTracker = useAnalyticsEventTracker('Home');

  const featureRef = useRef<HTMLDivElement | null>(null);
  const [featureAnimation, setFeatureAnimation] = useState(false);
  const howItWorksRef = useRef<HTMLDivElement | null>(null);
  const [howItWorksAnimation, setHowItWorksAnimation] = useState(false);
  const whyRef = useRef<HTMLDivElement | null>(null);
  const [whyRouteBuddiesAnimation, setWhyRouteBuddiesAnimation] =
    useState(false);
  const commRef = useRef<HTMLDivElement | null>(null);
  const [commAnimation, setCommAnimation] = useState(false);

  const ourStoryRef = useRef<HTMLDivElement | null>(null);
  const [ourStoryAnimation, setOurStory] = useState(false);

  const asSeenOnRef = useRef<HTMLDivElement | null>(null);
  const [asSeenOnAnimation, setAsSeenOnAnimation] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function isWithinView(ref: any, setShow: Function, range: number) {
    try {
      if (!ref && !ref.current) return;
      if (ref && ref !== null && ref.current && ref.current !== null) {
        const { top } = ref.current.getBoundingClientRect();

        if (top < range) setShow(true);
      } else {
        return;
      }
    } catch (error) {}
  }
  useEffect(() => {
    !featureAnimation &&
      isWithinView(featureRef, setFeatureAnimation, window.innerHeight * 0.7);
    !howItWorksAnimation &&
      isWithinView(
        howItWorksRef,
        setHowItWorksAnimation,
        window.innerHeight * 0.7
      );
    !whyRouteBuddiesAnimation &&
      isWithinView(
        whyRef,
        setWhyRouteBuddiesAnimation,
        window.innerHeight * 0.7
      );
    !commAnimation &&
      isWithinView(commRef, setCommAnimation, window.innerHeight * 0.7);
    !ourStoryAnimation &&
      isWithinView(ourStoryRef, setOurStory, window.innerHeight * 0.7);
  }, [scrollPosition]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage(PageType.HOME));
  }, []);

  function openAppStore() {
    gaEventTracker('go_to_reg_page');
    window.location.href = '/register';
  }

  const featureItems = [
    {
      title: 'Community Spaces',
      description:
        'Create or join private spaces with the people you trust most.',
      imgSrc: '/static/community_icon.png',
    },
    {
      title: 'Local Feeds',
      description:
        'Stay updated and proactive with real-time local safety news.',
      imgSrc: '/static/feed_icon.png',
    },
    {
      title: 'Group-Up',
      description: 'Make your events ',
      imgSrc: '/static/fist_icon.png',
    },
    {
      title: 'Emergency Ready',
      description: 'Automated emergency alerts to contacts and police.',
      imgSrc: '/static/danger_icon.png',
    },
  ];

  const section5Items = [
    {
      title: 'Communities',
      description: 'Join our free public communities, or join a private space.',
      imgSrc: '/static/verified_icon.png',
      imgStyle: { width: '100%', maxWidth: 100 },
    },
    {
      title: 'Find Buddies',
      description:
        "Join communities you for your day-to-day activities. Whether it's your daily dog walk, or a jog around the park!",
      imgSrc: '/static/group_icon.png',
      imgStyle: {
        width: '100%',
        maxWidth: 100,
        paddingTop: '13px',
        paddingBottom: '13px',
      },
    },
    {
      title: 'Make Changes',
      description:
        "Be a part of the larger change we're making in your local area, join our fight for street safety for all.",
      imgSrc: '/static/fist_icon.png',
      imgStyle: { width: '100%', maxWidth: 100 },
    },
  ];

  function Carousel() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        style={{
          margin: 3,
          marginBottom: '10px',
          padding: 40,
          width: '100%',
          alignItems: 'center',
          color: '#333',
        }}
      >
        <Slider {...settings}>
          <div style={localStyles.centerVert}>
            <a href="https://www.walesonline.co.uk/news/wales-news/first-kind-app-launches-cardiff-26209655">
              <img
                style={{ width: '90%', margin: '5%', marginTop: '20px' }}
                src={'/static/walesonline.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://businessnewswales.com/a-cardiff-entrepreneur-launches-new-app-to-prevent-street-harassment/">
              <img
                style={{ width: '90%', margin: '5%' }}
                src={'/static/itvwales.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://www.wales247.co.uk/new-app-to-prevent-street-harassment-of-women-in-cardiff">
              <img
                style={{ width: '90%', margin: '5%', marginTop: '25px' }}
                src={'/static/wales247.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://businessnewswales.com/a-cardiff-entrepreneur-launches-new-app-to-prevent-street-harassment/">
              <img
                style={{ width: '90%', margin: '5%' }}
                src={'/static/businessnewswales.png'}
              />
            </a>
          </div>
        </Slider>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />

      <Grid
        container
        flexDirection={'column'}
        sx={{
          maxWidth: '100vw',
        }}
      >
        {/* Section 1 - Intro*/}
        <Grid
          container
          flexDirection={'row'}
          style={{
            backgroundColor: 'white',
          }}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            sx={{
              backgroundImage: `url(${'/static/login-bground.jpg'})`,
              backgroundSize: 'cover',
              height: { xs: `100vh`, sm: 'calc(100vh - 100px)' },
            }}
            xs={12}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              md={9}
              sx={{
                height: {
                  xs: `100vh`,
                  md: `auto`,
                },
                minHeight: '80vh',

                marginBottom: {
                  xs: 5,
                  sm: 0,
                },
              }}
              style={{
                overflow: 'visible',
                // height: 'Calc(100vh - 160px)',
              }}
            >
              {/* Text */}
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                sx={{ padding: { xs: '25px', md: '10px' } }}
                style={{
                  height: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Fade
                  in={fadeAnimation}
                  easing={{ enter: theme.transitions.easing.easeInOut }}
                  timeout={500}
                >
                  <div>
                    <Typography component="h1" variant="h3" color="white">
                      Safety in <strong>numbers.</strong>
                    </Typography>{' '}
                    <Typography
                      variant={'body2'}
                      color={'white'}
                      style={{
                        fontFamily: 'Urbanist',
                        paddingTop: '10px',
                        fontSize: '17px',
                        paddingBottom: '20px',
                      }}
                    >
                      RouteBuddies is the safety app bringing communities
                      together.
                    </Typography>
                    <Grid
                      item
                      container
                      xs={12}
                      flexDirection={'row'}
                      alignItems={'center'}
                      alignContent={'center'}
                      sx={{ marginTop: '40px' }}
                    >
                      <RoundedButton
                        defaultColor={'white'}
                        defaultBackgroundColor={primaryColor}
                        hoverColor={primaryColor}
                        hoverBackgroundColor={'white'}
                        onClick={() => {
                          executeScroll(featureRef);
                        }}
                        width={120}
                        sx={{
                          marginTop: 0,
                          fontSize: '0.7em',
                        }}
                      >
                        Learn more
                      </RoundedButton>
                      <RoundedButton
                        defaultColor={'white'}
                        defaultBackgroundColor={'white'}
                        hoverColor={'white'}
                        hoverBackgroundColor={primaryColor}
                        textColor={primaryColor}
                        width={120}
                        onClick={() => {
                          dispatch(setCurrentPage(PageType.ENTERPRISE));
                          window.scrollTo(0, 0);
                          navigate('/enterprise');
                        }}
                        sx={{
                          marginLeft: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                          fontSize: '0.7em',
                        }}
                      >
                        Enterprise
                      </RoundedButton>
                    </Grid>
                  </div>
                </Fade>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                height: { xs: 'auto', md: '200' },
                display: { xs: 'block', md: 'none' },
              }}
            ></Grid>
          </Grid>
          <VSNIAnnouncement />

          <Grid xs={6} sm={12} md={7}></Grid>
          {/* image */}
          <Grid
            item
            xs={6}
            sm={12}
            md={5}
            justifyContent={'center'}
            sx={{
              height: { xs: 'auto', md: '200' },
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          >
            <Slide
              direction="left"
              in={initialAnimationStart}
              timeout={500}
              easing={{ enter: theme.transitions.easing.easeInOut }}
              onEntered={() => {
                setFadeAnimation(true);
              }}
            >
              <Grid
                sx={{
                  width: { xs: '100%', sm: '300px', md: '370px', lg: '360px' },
                  position: { xs: 'relative', md: 'absolute' },
                  top: 20,
                }}
              >
                <img
                  src={'/static/app_phone.png'}
                  style={{
                    width: 240,
                    marginTop: 100,
                    zIndex: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </Grid>
            </Slide>
          </Grid>
        </Grid>

        {/* Section 2 - Features*/}

        <div ref={featureRef} style={{ height: '100%', width: '100%' }}>
          {/* Inner Container - Less page width */}

          {/* Section 4 -Why does RouteBuddies Exist */}
          <Grid
            container
            item
            xs={12}
            ref={whyRef}
            justifyContent={'center'}
            sx={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <Fade
              in={whyRouteBuddiesAnimation}
              easing={{ enter: theme.transitions.easing.easeInOut }}
              timeout={500}
            >
              <Grid container item xs={12} sm={10}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <img
                    src={'/static/girl_walking.svg'}
                    style={{ width: '80%', maxWidth: 350 }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid
                    container
                    item
                    xs={10}
                    sx={{
                      marginTop: { xs: 5, sm: 10 },
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h4"
                      color="primary"
                      sx={{ marginBottom: '10px' }}
                    >
                      Why RouteBuddies?
                    </Typography>
                    <Typography
                      variant={'body2'}
                      color={'#000'}
                      style={{ marginBottom: 10 }}
                    >
                      Walking alone is scary (50% of women in the UK say so!),
                      which is why we created a way so people{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        didn't have to.
                      </span>
                    </Typography>
                    <Typography
                      variant={'body2'}
                      color={'#000'}
                      style={{ marginBottom: 10 }}
                    >
                      RouteBuddies allows anybody to find or create a community
                      of like-minded individuals to keep eachother safe, because
                      together we're stronger.
                    </Typography>{' '}
                    <RoundedButton
                      defaultColor={primaryColor}
                      defaultBackgroundColor={'white'}
                      hoverColor={'white'}
                      hoverBackgroundColor={primaryColor}
                      onClick={() => {
                        window.location.href =
                          'https://routebuddies.com/?appTrigger=true';
                      }}
                      style={{ marginTop: 30 }}
                      width={190}
                    >
                      Get the app
                    </RoundedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </div>
      </Grid>

      {/* Section 3 - How it works */}

      <Features />

      <div style={{ height: '100%', width: '100%' }}>
        {/* Inner Container - Less page width */}

        {/* Section 4 -Why does RouteBuddies Exist */}
        <Grid
          container
          item
          xs={12}
          ref={commRef}
          justifyContent={'center'}
          sx={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <Fade
            in={commAnimation}
            easing={{ enter: theme.transitions.easing.easeInOut }}
            timeout={500}
          >
            <Grid container item xs={12} sm={10}>
              <Grid
                container
                item
                xs={12}
                sm={6}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid
                  container
                  item
                  xs={10}
                  sx={{
                    marginTop: { xs: 5, sm: 0 },
                    justifyContent: { xs: 'center', sm: 'flex-start' },
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h4"
                    color="primary"
                    sx={{ marginBottom: '10px' }}
                  >
                    Communities are our <strong>jam.</strong>
                  </Typography>
                  <Typography
                    variant={'body2'}
                    color={'#000'}
                    style={{ marginBottom: 10 }}
                  >
                    Start finding safety in numbers today with RouteBuddies'
                    communities!
                  </Typography>{' '}
                  <Typography
                    variant={'body2'}
                    color={'#000'}
                    style={{ marginBottom: 10 }}
                  >
                    Join or create a community as a safety net, and become an
                    active bystander for your buddies.
                  </Typography>
                  <Typography
                    variant={'body2'}
                    color={'#000'}
                    style={{ marginBottom: 10 }}
                  >
                    You can download the app and use it for free, but we also
                    provide bespoke enterprise solutions too!
                  </Typography>
                  <RoundedButton
                    defaultColor={primaryColor}
                    defaultBackgroundColor={'white'}
                    hoverColor={'white'}
                    hoverBackgroundColor={primaryColor}
                    textColor={primaryColor}
                    onClick={() => {
                      dispatch(setCurrentPage(PageType.ENTERPRISE));
                      window.scrollTo(0, 0);
                      navigate('/enterprise');
                    }}
                    width={200}
                    sx={{
                      width: { xs: 100, sm: 200 },
                      marginTop: { xs: 2, sm: 2 },
                    }}
                  >
                    Enterprise
                  </RoundedButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img
                  src={'/static/connecting.png'}
                  style={{ width: '80%', maxWidth: 350 }}
                />
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </div>
      {/* Section 3 - News - Carosel*/}
      <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        sx={{
          paddingTop: 5,
          backgroundColor: primaryColor,
          paddingBottom: 10,
        }}
      >
        <Grid item container xs={12} justifyContent={'center'}>
          <Typography
            component="h2"
            variant="h4"
            color="white"
            sx={{ marginBottom: '10px' }}
          >
            As seen on:
          </Typography>
        </Grid>

        <Grid
          xs={12}
          sm={10}
          md={8}
          lg={8}
          sx={{ marginBottom: { xs: 5, sm: 0 } }}
        >
          <Carousel />
        </Grid>
        <Grid item container xs={12} justifyContent={'center'}>
          <RoundedButton
            defaultColor={'white'}
            defaultBackgroundColor={primaryColor}
            hoverColor={primaryColor}
            hoverBackgroundColor={'white'}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/news');
              dispatch(setCurrentPage(PageType.NEWS));
            }}
            width={200}
          >
            See More
          </RoundedButton>
        </Grid>
      </Grid>

      {/* <Grid container item xs={12} justifyContent={'center'}>
        <Grid
          container
          item
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Fade in={fadeAnimation} timeout={500}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid
                item
                xs={12}
                style={
                  isMobile
                    ? {
                        height: '100%',
                        padding: 15,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom right',
                        backgroundImage: `url(${'/static/harrass.jpg'})`,
                      }
                    : {
                        height: '100%',
                        padding: 15,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom center',
                        backgroundImage: `url(${'/static/harrass.jpg'})`,
                      }
                }
                sx={{ minHeight: { xs: 280, md: 400 } }}
              />
            </Grid>
          </Fade>
          <Slide direction="left" in={fadeAnimation} timeout={500}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid
                item
                container
                justifyContent={'center'}
                xs={11}
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  padding: 15,
                  height: '100%',
                }}
              >
                <Typography
                  variant={'h5'}
                  color={'primary'}
                  textAlign="left"
                  style={{
                    fontFamily: 'Urbanist',
                    marginBottom: 10,
                    width: '100%',
                    fontWeight: '700',
                  }}
                >
                  Our Story
                </Typography>
                <Typography
                  variant={'body2'}
                  color={'primary'}
                  style={{ marginBottom: 10 }}
                >
                  The 2020 Sarah Everard incident was a pivotal moment
                  worldwide, highlighting the urgent need for personal safety.
                </Typography>
                <Typography
                  variant={'body2'}
                  color={'primary'}
                  style={{ marginBottom: 10 }}
                >
                  Our founder, inspired by the unity of countless protesters,
                  envisioned an innovative safety app that encourages community
                  collaboration for enhancing personal security.
                </Typography>
                <Typography
                  variant={'body2'}
                  color={'primary'}
                  style={{ marginBottom: 10 }}
                >
                  This platform empowers individuals, putting the tools for
                  change and safety control directly into user hands.
                </Typography>

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'center'}
                  ref={ourStoryRef}
                >
                  <Button
                    color={'primary'}
                    style={{
                      padding: 10,
                      marginTop: 15,
                      backgroundColor: !mouseOverDownload
                        ? 'white'
                        : primaryColor,
                      width: 250,
                      justifyContent: 'center',
                      color: !mouseOverDownload ? primaryColor : 'white',
                      alignItems: 'center',
                      borderRadius: 43,
                      border: '5px solid #cd0438',
                      fontFamily: 'Urbanist',
                    }}
                    onClick={() => {
                      openAppStore();
                    }}
                    onMouseOver={() => {
                      setMouseOverDownload(true);
                    }}
                    onMouseOut={() => {
                      setMouseOverDownload(false);
                    }}
                  >
                    Sign me up!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </Grid> */}
    </>
  );
}
