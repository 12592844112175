//Methods for firebase connectivity
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyC-QXaU6jszz15l6k6H742akU_IIL4aKvw',
  authDomain: 'routebuddies-app.firebaseapp.com',
  databaseURL: 'https://routebuddies-app.firebaseio.com',
  projectId: 'routebuddies-app',
  storageBucket: 'routebuddies-app.appspot.com',
  messagingSenderId: '105749045547',
  appId: '1:105749045547:web:deffae402d42e0941aa56e',
  measurementId: 'G-7HPL4TC2ZH',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };

export function sendNotificationToTopic(
  topic: string,
  title: string,
  body: string,
  data: any
) {
  db.ref('/new_data/notificationsend').push({
    topic,
    title,
    body,
    data,
  });
}

export function sendNotification(
  topic: string,
  title: string,
  body: string,
  data: any,
  type: string,
  image?: string
) {
  // Topic is either topic or USER ID...
  let ref = db.ref('/new_data/notificationsend').push({
    topic,
    title,
    body,
    data,
    type,
    image,
  });

  if (ref.key) {
    db.ref('/new_data/notificationsend/' + ref.key + '/id').set(ref.key);
  }
}
