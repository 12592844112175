import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Tooltip, Grid, Avatar } from '@mui/material';

export default function VerificationCard({
  theme,
  ver,
  user,
  setSelectedVerification,
}: any) {
  if (user && user !== null) {
    return (
      <Tooltip title="View Verification">
        <Card
          sx={{
            height: '100%',
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.main,
          }}
          onClick={() => {
            setSelectedVerification({ ver, user });
          }}
        >
          <div style={{ color: theme.palette.primary.main }}>
            <Grid
              container
              item
              xs={12}
              alignItems={'center'}
              style={{
                backgroundColor: 'grey',
                height: '100%',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 12,
                  padding: '2px 5px',
                  borderRadius: 10,
                }}
              >
                {ver.status === 'RESPONSE_NEEDED'
                  ? 'Verification Needed'
                  : ver.identityVerification
                  ? 'Accepted'
                  : 'Denied'}
              </Typography>
            </Grid>
          </div>
          <CardContent>
            <Grid container justifyContent={'space-between'}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color={theme.palette.text.main}
              >
                {user.firstName + ' ' + user.lastName}
              </Typography>
              <Avatar alt={user.firstName + ' ' + user.lastName}>
                {user.firstName[0]}
              </Avatar>
            </Grid>
            <Typography gutterBottom variant="body1" component="div">
              {ver !== null && ver.dateTime}
            </Typography>
          </CardContent>
          <div className={'flex-grow'} />
        </Card>
      </Tooltip>
    );
  } else {
    return <></>;
  }
}
